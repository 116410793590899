var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DayDetailsMolecule', [_c('div', {
    staticClass: "heading"
  }, [_c('div', {
    staticClass: "week-day"
  }, [_vm._v(" " + _vm._s(_vm.weekday) + " ")]), _c('div', {
    staticClass: "day"
  }, [_vm._v(" " + _vm._s(_vm.dayString) + " ")]), _c('div', {
    staticClass: "close"
  }, [_c('CloseButtonAtom', {
    on: {
      "close": function close($event) {
        return _vm.$emit('closeDetails');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "schedules"
  }, _vm._l(_vm.schedules, function (schedule) {
    return _c('ScheduleDetailsStyled', {
      key: schedule === null || schedule === void 0 ? void 0 : schedule.id,
      attrs: {
        "bgColor": schedule.bgColor
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.editSchedule(schedule);
        }
      }
    }, [_c('div', {
      staticClass: "circle"
    }), _c('div', [_vm._v(_vm._s(_vm.scheduleStart(schedule)))]), _c('div', {
      staticClass: "name",
      attrs: {
        "title": schedule === null || schedule === void 0 ? void 0 : schedule.name
      }
    }, [_vm._v(" " + _vm._s(schedule === null || schedule === void 0 ? void 0 : schedule.name))])]);
  }), 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }