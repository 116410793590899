<template>
  <DayDetailsMolecule>
    <div class="heading">
      <div class="week-day">
        {{ weekday }}
      </div>
      <div class="day">
        {{ dayString }}
      </div>
      <div class="close">
        <CloseButtonAtom @close="$emit('closeDetails')" />
      </div>
    </div>
    <div class="schedules" >
      <ScheduleDetailsStyled v-for="(schedule) of schedules" :key="schedule?.id" :bgColor="schedule.bgColor" @click.stop="editSchedule(schedule)">
        <div class="circle"></div>
        <div>{{ scheduleStart(schedule) }}</div>
        <div class="name" :title="schedule?.name"> {{ schedule?.name }}</div> 
      </ScheduleDetailsStyled>
    </div>
  </DayDetailsMolecule>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'

import CloseButtonAtom from '../../Atomic/Atoms/CloseButtonAtom'

const DayDetailsMolecule = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 220px;
  width:100%;
  background-color: ${({ theme }) => theme.colors.atomic.hover};
  box-shadow: 0 1px 1px 0 rgba(60,64,67,.3), 0 4px 8px 1px rgba(60,64,67,.15);
  align-items: center;
  padding: 1rem;
  border-radius: 5px;
  .heading{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    align-items: center;
    position: relative;
    .week-day{
      font-size: 11px;
      font-weight: 400;
      letter-spacing: .8px;
      line-height: 16px;
      text-transform: uppercase;
    }
    .day{
      font-size: 26px;
      font-weight: 400;
    }
    .close{
      position: absolute;
      right: 0;
    }
  }
  .schedules{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
`

const ScheduleDetailsStyled = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 10px min-content 1fr; 
  grid-gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  .circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
  }
  &:hover{
    background: ${({ theme }) => theme.colors.primaryActive};
    cursor: pointer;
  }
`

export default {
  components: {
    DayDetailsMolecule,
    ScheduleDetailsStyled,
    CloseButtonAtom,
  },
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
    day: {
      type: Date,
    },
    selectedTimezoneId: {
      type: String,
      required: true,
    },
  },
  computed:{
    dayString(){
      return DateTime.fromJSDate(this.day).toLocaleString({ day: '2-digit' }) 
    },
    weekday(){
      return DateTime.fromJSDate(this.day).toLocaleString({ weekday: 'short' }) 
    },
  },
  methods:{
    scheduleStart(schedule){
      if(!schedule?.recurrences[0]){
        return null
      }
      return this.getDateTimeInTimezoneBySetting(DateTime.fromISO(schedule.recurrences[0].start), schedule.scheduleTimezone, this.selectedTimezoneId).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    getDateTimeInTimezoneBySetting(dt, timezone, setting) {
      if (setting === 'schedule') {
        return dt.setZone(timezone)
      } else if (setting === 'utc') {
        return dt.setZone('UTC')
      } else if (setting === 'local') {
        return dt.setZone('local')
      }
    },
    editSchedule(schedule) {
      this.$emit('editSchedule', schedule)
    },
  },
}
</script>